/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';

/* lazy image */
@import 'react-lazy-load-image-component/src/effects/blur.css';

/* editor */
@import 'react-quill/dist/quill.snow.css';

/* carousel */
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

.rbt-input-main.form-control.rbt-input {
  height: 20px;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  box-sizing: content-box;
  background: none;
  min-height: 1.2em;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 80%;
  padding: 12.5px 14px;
  border-radius: 10px;
}

.rbt-aux {
  width: 37% !important;
}
.rbt-input-multi.focus,
.rbt-input-main.focus {
  border-color: #bac48c;
  box-shadow: 0 0 0 0.1rem #bac48c;
  outline: 0;
}
.rbt-menu.dropdown-menu.show {
  position: relative !important;
  transform: none !important;
  margin-top: -150px;
}

.ant-select-selector {
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  font-size: 15px;
  height: 50px;
  padding: 0 11px;
  width: 100%;
  margin-bottom: 10px;
  outline: 0;
}
.ant-select-selector:hover,
.ant-select-selector:active,
.ant-select-selector:focus {
  border-color: black !important;
}
